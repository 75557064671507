import { useState } from 'react';
import {
  CommentAnalysisTag,
  CommentsAnalysis,
} from '../../__generated__/graphql';
import { Strong, Text, TextLink } from '../catalyst/text';
import { Subheading } from '../catalyst/heading';
import { Badge } from '../catalyst/badge';
import {
  ChevronDownIcon,
  ChevronRightIcon,
  PlusIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { Button } from '../catalyst/button';

export default function CommentsAnalysisFeed({
  commentsAnalysis,
}: {
  commentsAnalysis: CommentsAnalysis[];
}) {
  return (
    <div className="space-y-3 divide-y pb-6">
      {commentsAnalysis.map((analysis) => (
        <CommentAnalysisComponent commentsAnalysis={analysis} />
      ))}
    </div>
  );
}

function CommentAnalysisComponent({
  commentsAnalysis,
}: {
  commentsAnalysis: CommentsAnalysis;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedTag, setSelectedTag] = useState<CommentAnalysisTag>(
    commentsAnalysis.tags?.[0]
  );

  const formattedDateHeader = (date: string) => {
    return `Week of ${new Date(Number(date)).toLocaleDateString('en-us')}`;
  };

  return (
    <div
      key={commentsAnalysis.id}
      className="space-y-4 pt-3"
    >
      <div
        className="flex items-center space-x-2 rounded-md bg-gray-100 p-2"
        onClick={() => setIsOpen(!isOpen)}
      >
        <Button
          outline
          onClick={() => setIsOpen(!isOpen)}
          className="hover:cursor-pointer"
        >
          {isOpen ? <ChevronDownIcon /> : <ChevronRightIcon />}
        </Button>
        <Subheading>{formattedDateHeader(commentsAnalysis.date)}</Subheading>
      </div>
      <div className={`${isOpen ? '' : 'hidden'} space-y-4`}>
        {/* Summary */}
        <div className="space-y-2 pr-6">
          <Strong>Brand sentiment</Strong>
          <Text>{commentsAnalysis.summary}</Text>
        </div>

        {/* Tags */}
        {commentsAnalysis.tags?.length > 0 && (
          <div className="flex gap-4">
            {commentsAnalysis.tags?.map((tag) => (
              <Badge
                key={tag.name}
                color={selectedTag?.name !== tag.name ? 'green' : 'cyan'}
                className={`hover:cursor-pointer hover:underline ${
                  selectedTag?.name === tag.name ? 'font-bold underline' : ''
                }`}
                onClick={() => setSelectedTag(tag)}
              >
                {tag.name}
              </Badge>
            ))}
          </div>
        )}

        {selectedTag && (
          <div className="space-y-2 rounded-md border p-4">
            {selectedTag.comments?.map((comment) => (
              <div key={comment.text}>
                <Text className="italic">
                  "{comment.text}"
                  {comment.postUrl && (
                    <TextLink
                      className="pl-2 font-semibold not-italic text-blue-500"
                      href={comment.postUrl}
                      target="_blank"
                    >
                      View post.
                    </TextLink>
                  )}
                </Text>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
