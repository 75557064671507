import { useQuery } from '@apollo/client';
import { Heading } from '../components/catalyst/heading';
import { COMMENTS_ANALYSIS, CommentsAnalysisData } from '../graphql/queries';
import { useContext } from 'react';
import { UserContext } from '../providers/UserProvider';
import { Text } from '../components/catalyst/text';
import { Spinner } from '@kalecard/common';
import EmptyDataState from '../components/EmptyDataState';
import CommentsAnalysisFeed from '../components/comments/CommentsAnalysisFeed';

export default function CommentsAnalysisPage({}) {
  const { brandId } = useContext(UserContext);
  const { data, loading } = useQuery<CommentsAnalysisData>(COMMENTS_ANALYSIS, {
    variables: {
      brandId: brandId,
    },
    skip: !brandId,
  });

  return (
    <div className="space-y-3 sm:px-6 md:px-8">
      {/* Header */}
      <div>
        <Heading>Comments Analysis</Heading>
        <Text>See what the community is saying about you.</Text>
      </div>

      {loading ? (
        <div className="flex justify-center">
          <Spinner size="h-8 w-8" />
        </div>
      ) : data.commentsAnalysis?.length > 0 ? (
        <CommentsAnalysisFeed commentsAnalysis={data.commentsAnalysis} />
      ) : (
        <EmptyDataState message="Comments analysis coming soon!" />
      )}
    </div>
  );
}
