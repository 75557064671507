import { useContext, useEffect, useState } from 'react';
import AllContent from './AllContent';
import { ContentContext } from '../../providers/ContentProvider';
import {
  ChevronUpDownIcon,
  MagnifyingGlassIcon,
} from '@heroicons/react/24/outline';
import { sortingTypes } from './SortingTypes';
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import { DateRange } from 'rsuite/esm/DateRangePicker';
import { ContentActionType } from '../../reducers/ContentReducer';
import { Input, InputGroup } from '../catalyst/input';
import { Dropdown, DropdownButton, DropdownMenu } from '../catalyst/dropdown';
import { Checkbox, CheckboxField, CheckboxGroup } from '../catalyst/checkbox';
import { Label } from '../catalyst/fieldset';

export default function BrandsContent() {
  const {
    state: {
      sortingType,
      challengeId,
      selectableChallenges,
      startDate,
      endDate,
      selectedChallengeIds,
      creatorHandle,
    },
    dispatch: contentDispatch,
  } = useContext(ContentContext);
  const [localCreatorHandle, setLocalCreatorHandle] = useState(creatorHandle);

  useEffect(() => {
    let query = '';
    if (creatorHandle) {
      query += `?creatorHandle=${creatorHandle}`;
    }
    if (selectedChallengeIds?.length > 0) {
      query += `${
        query ? '&' : '?'
      }activeChallenges=${selectedChallengeIds.join(',')}`;
    }
    if (startDate) {
      query += `${query ? '&' : '?'}startDate=${startDate}`;
    }
    if (endDate) {
      query += `${query ? '&' : '?'}endDate=${endDate}`;
    }

    if (query === '') {
      // Remove the query parameter completely from the URL
      window.history.replaceState({}, '', window.location.pathname);
    } else {
      window.history.replaceState({}, '', query);
    }
  }, [creatorHandle, selectedChallengeIds, startDate, endDate]);

  const setDateFilters = (dateRange?: DateRange) => {
    contentDispatch({
      type: ContentActionType.SET_DATE_FILTERS,
      payload: {
        startDate: dateRange ? dateRange[0]?.getTime() : null,
        endDate: dateRange ? dateRange[1]?.getTime() : null,
      },
    });
  };

  const handleChallengeToggle = (challengeId: string) => {
    contentDispatch({
      type: ContentActionType.TOGGLE_CHALLENGE_FILTER,
      payload: {
        challengeId: challengeId,
      },
    });
  };

  const handleCreatorHandleChange = (e) => {
    setLocalCreatorHandle(e.target.value);
  };

  const handleSearchKeyDown = (e) => {
    if (e.key === 'Enter') {
      contentDispatch({
        type: ContentActionType.SET_CREATOR_HANDLE_FILTER,
        payload: {
          creatorHandle: localCreatorHandle,
        },
      });
    }
  };

  const isChallengeChecked = (challengeId: string) => {
    return selectedChallengeIds?.find((id) => id === challengeId) != null;
  };

  const challengesDropdown = () => {
    return (
      <Dropdown>
        <DropdownButton outline>
          <p className="text-sm font-normal text-gray-500">
            Select active challenges
          </p>
          <ChevronUpDownIcon />
        </DropdownButton>
        <DropdownMenu>
          <div className="flex w-96 flex-col space-y-2 p-2">
            <div>
              <CheckboxGroup>
                {selectableChallenges.map((challenge) => (
                  <CheckboxField key={challenge.id}>
                    <Checkbox
                      name={challenge.description}
                      color="emerald"
                      checked={isChallengeChecked(challenge.id)}
                      onClick={() => handleChallengeToggle(challenge.id)}
                    />
                    <div className="flex justify-between">
                      <Label>{challenge.description}</Label>
                    </div>
                  </CheckboxField>
                ))}
              </CheckboxGroup>
            </div>
          </div>
        </DropdownMenu>
      </Dropdown>
    );
  };

  return (
    <>
      <div className="py-2 sm:px-6 md:px-8">
        <div className="mb-2 flex items-center justify-between border-b">
          {sortingTypes(contentDispatch, sortingType)}
        </div>
        <div className="flex items-center space-x-2">
          <div className="w-60">
            <InputGroup>
              <MagnifyingGlassIcon />
              <Input
                onChange={handleCreatorHandleChange}
                name="search"
                placeholder="Search by creator handle"
                aria-label="Search"
                onKeyDown={handleSearchKeyDown}
                defaultValue={localCreatorHandle}
              />
            </InputGroup>
          </div>
          <div>{selectableChallenges.length > 0 && challengesDropdown()}</div>
          <div className="rounded-md border border-gray-300 py-0.5">
            <DatePicker
              onDateRangeChange={(dateRange) => setDateFilters(dateRange)}
              startDate={startDate}
              endDate={endDate}
            />
          </div>
        </div>
      </div>
      <AllContent />
    </>
  );
}

function DatePicker({
  onDateRangeChange,
  startDate,
  endDate,
}: {
  onDateRangeChange: (dateRange?: DateRange) => void;
  startDate?: string;
  endDate?: string;
}) {
  const { afterToday } = DateRangePicker;
  return (
    <DateRangePicker
      format="MM/dd/yyyy"
      character=" – "
      shouldDisableDate={afterToday()}
      placeholder={
        startDate && endDate
          ? `${new Date(Number(startDate)).toLocaleDateString(
              'en-us'
            )} - ${new Date(Number(endDate)).toLocaleDateString('en-us')}`
          : 'Select date range'
      }
      size="sm"
      showOneCalendar
      appearance="subtle"
      onChange={onDateRangeChange}
      defaultValue={
        startDate && endDate ? [new Date(startDate), new Date(endDate)] : null
      }
    />
  );
}
